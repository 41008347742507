import type {
  ApiV2ExpertModeHighlightContent,
  ApiV2ExpertModeHighlightSpyroContent,
} from '@dce-front/hodor-types';
import type { ApiV2Contents } from '@dce-front/hodor-types/api/v2/content_grid/definitions';
import type { ApiV2SpyroSearchContent } from '@dce-front/hodor-types/api/v2/search/definitions';
import { contentGridTv } from '@dce-front/onewebapp-core-css';
import { DisplayMode, Ratio } from '@dce-front/onewebapp-utils';
import type { PersoLists } from '@dce-front/sdk-hodor';
import { Template, TitleDisplayMode } from '@dce-front/sdk-hodor';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames/bind';
import { useEffect, useMemo } from 'react';
import ContentGridTemplateItem from '../../../components/ContentGrid/ContentGridTemplateItem';
import { useIsFrom } from '../../../components/Page/useIsFrom';
import {
  applicationResizeSelector,
  featUpcomingHodorStickerSelector,
} from '../../../store/slices/application-selectors';

import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';

import type { ImageSize } from '@dce-front/onewebapp-dive-utils';
import {
  getMediaDimensions,
  transformMediaDimensionsToPx,
} from '../../../helpers/mediaDimensions/getMediaDimensions';
import type { ContentStrateV5 } from '../../LandingV5/data/formatter';
import styles from './ContentGridTemplate.css';
import type { ContentGridTemplateProps } from './ContentGridTemplate.types';

const cx = classNames.bind(styles);

type ContentGridVirtualProps = {
  data: ContentGridTemplateProps['data'];
  contentGridItemProps: {
    isRemovableItem?: boolean;
    isSearch?: boolean;
    listType?: PersoLists;
    onClickRemove?: (
      event: React.MouseEvent,
      contentID: string,
      listType: PersoLists,
    ) => void;
    titleDisplayMode?: TitleDisplayMode;
  };
  onClickProp?: { onClick?: () => void };
  imageRatio: Ratio;
  imageSize: ImageSize;
  classNameGridItem?: string;
  offsetTop?: number;
  focusManager?: FocusManager;
  isInitialOffsetVirtualization?: boolean;
};

export function ContentGridVirtual({
  data,
  contentGridItemProps,
  onClickProp,
  imageRatio = Ratio.Ratio169,
  imageSize = 'normal',
  classNameGridItem,
  offsetTop,
  focusManager,
  isInitialOffsetVirtualization = false,
}: ContentGridVirtualProps) {
  const isFeatUpcomingHodorSticker = useInvariantSelector(
    featUpcomingHodorStickerSelector,
  );
  const isFromDetail = useIsFrom(Template.DetailPage);

  const {
    isRemovableItem,
    isSearch,
    listType,
    onClickRemove,
    titleDisplayMode = TitleDisplayMode.All,
  } = contentGridItemProps;

  const numberOfItemOnScreen = (contentGridTv as any)?.[
    `${imageRatio}${imageSize}`
  ]?.wide;
  const resizeMode = useInvariantSelector(applicationResizeSelector);

  const mediaDimensions = useMemo(
    () =>
      transformMediaDimensionsToPx(
        getMediaDimensions({
          numberOfItemOnScreen,
          ratio: imageRatio,
          titleDisplayMode,
          paddingTopBottomItem: 1.25,
        }),
        resizeMode,
      ),
    [imageRatio, numberOfItemOnScreen, resizeMode, titleDisplayMode],
  );

  const contents = useMemo(() => {
    const results: Array<
      | ApiV2Contents
      | ContentStrateV5
      | ApiV2SpyroSearchContent
      | ApiV2ExpertModeHighlightSpyroContent
      | ApiV2ExpertModeHighlightContent
    > = [];

    if (!data?.pages.length) {
      return results;
    }

    for (let i = 0; i < data?.pages.length; i++) {
      const newContents = data.pages[i]?.contents;
      if (newContents) {
        results.push(...newContents);
      }
    }

    return results;
  }, [data?.pages]);

  const focusState = focusManager?.getFocusState();
  const initialElementIndex = focusState?.elementIndex ?? 0;

  const virtualizer = useWindowVirtualizer({
    horizontal: false,
    count: contents.length,
    estimateSize: () => mediaDimensions.heightMediaCard,
    scrollMargin: offsetTop ?? 0,
    overscan: Math.max(numberOfItemOnScreen * 2, initialElementIndex),
    lanes: numberOfItemOnScreen,
    ...(isInitialOffsetVirtualization && { initialOffset: 0 }), // workaround, force scroll to top when coming back to a content grid template page
  });

  useEffect(() => {
    virtualizer.scrollToIndex(initialElementIndex);
  }, [initialElementIndex, virtualizer]);

  const rowItems = virtualizer.getVirtualItems();

  useOnFocusable(focusManager, !!rowItems.length);

  return (
    <ul
      data-ratio={`${imageRatio}${imageSize}`}
      className={styles.contentGrid}
      style={{
        height: virtualizer.getTotalSize(),
        width: '100%',
        position: 'relative',
      }}
    >
      {rowItems.map((virtualRow) => {
        const content = contents?.[virtualRow.index];

        // DisplayMode.FULLSCREEN is without capital S -  waiting Hodor fix
        const isPlayBackIconShown =
          content?.onClick &&
          'displayMode' in content.onClick &&
          content?.onClick.displayMode?.toLowerCase() ===
            DisplayMode.FULLSCREEN;

        const isCreativeMedia = content?.type === Template.CreativeMedia;

        return (
          <li
            className={cx(
              'contentGrid__gridItem',
              {
                'contentGrid__gridItem--detailV5': isFromDetail,
                'contentGrid__gridItem--creativeMedia': isCreativeMedia,
              },
              classNameGridItem,
            )}
            key={virtualRow.key}
            style={{
              position: 'absolute',
              top: 0,
              left: `${virtualRow.lane * (100 / numberOfItemOnScreen)}%`,
              transform: `translateY(${virtualRow.start - virtualizer.options.scrollMargin}px)`,
            }}
            data-index={virtualRow.index}
            {...onClickProp}
          >
            <ContentGridTemplateItem
              content={content ?? null}
              imageSize={imageSize}
              ratio={imageRatio}
              titleDisplayMode={titleDisplayMode}
              isRemovableItem={isRemovableItem}
              onClickRemove={onClickRemove}
              listType={listType}
              isSearch={isSearch}
              isFeatUpcomingHodorSticker={isFeatUpcomingHodorSticker}
              isPlayBackIconShown={isPlayBackIconShown}
            />
          </li>
        );
      })}
    </ul>
  );
}
